import React from "react";

import { useStaticQuery, graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

// Components
import SecondaryLanding from "../../../layouts/secondary-landing";
import HeroChevron from "../../../components/hero/hero-chevron";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import MiniBand from "../../../components/mini-band/mini-band";
import CheckingOverviewSpanish from "../../../components/checking-overview/checking-overview-spanish";
import ContactInfo from "../../../components/contact-info/contact-info";
import SEO from "../../../components/seo/seo";
import getHeroImgVariables from "../../../helpers/getHeroImgVariables";
import List from "../../../components/custom-widgets/list";
import MobileAppDownloadBtn from "../../../components/mobile-online-banking/mobile-app-download-btn";

import WaFdMobileApp from "../../../images/wafd-mobile-app.gif";

const CheckingAccountSpanish = () => {
  const imgData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(
        relativePath: { eq: "hero/personal-banking/checking-account/hero-checking-030524-XXL.jpg" }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(relativePath: { eq: "hero/personal-banking/checking-account/hero-checking-030524-XL.jpg" }) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(relativePath: { eq: "hero/personal-banking/checking-account/hero-checking-030524-LG.jpg" }) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(relativePath: { eq: "hero/personal-banking/checking-account/hero-checking-030524-MD.jpg" }) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(relativePath: { eq: "hero/personal-banking/checking-account/hero-checking-030524-SM.jpg" }) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(relativePath: { eq: "hero/personal-banking/checking-account/hero-checking-030524-XS.jpg" }) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: { eq: "hero/personal-banking/checking-account/hero-checking-030524-XXS.jpg" }
      ) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);

  const heroChevronData = {
    id: "checking-account-hero",
    ...getHeroImgVariables(imgData),
    altText: "Mujer usa su celular mientras está sentada con las piernas cruzadas en un sofá.",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Abrir una cuenta de cheques en línea es rápido y fácil"
          }
        },
        {
          id: 2,
          button: {
            id: "checking-account-hero-instant-open-button",
            class: "btn-white",
            text: "Abrir una cuenta",
            url: "#checking-overview-section"
          }
        }
      ]
    }
  };

  const SEOData = {
    title: "Compare y abra una cuenta corriente en línea",
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        property: "og:title",
        content: "Compare y abra una cuenta corriente en línea"
      },
      {
        name: "description",
        property: "og:description",
        content:
          "Abra una cuenta corriente en línea con WaFd Bank. Compare cuentas corrientes y descubra las ventajas y beneficios de las cuentas corrientes, las recompensas y los intereses gratuitos."
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/es/banca-personal/cuenta-de-cheques"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-checking-030524.jpg"
      }
    ]
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/es",
      title: "Banca personal"
    },
    {
      id: 2,
      active: true,
      title: "Cuentas de Cheques"
    }
  ];

  const onlineMobileBankingListItems = {
    accountName: "online-mobile-banking-list",
    items: [
      {
        id: 1,
        text: "Pague facturas en línea o con su teléfono celular"
      },
      {
        id: 2,
        text: "Verifique su saldo, vea transacciones y transfiera dinero"
      },
      {
        id: 3,
        text: "Deposite cheques con su teléfono celular y deposite su cheque por foto de celular"
      },
      {
        id: 4,
        text: "Paga a amigos con transferencias de persona a persona"
      },
      {
        id: 5,
        text: "Realizar transferencias bancarias en línea "
      },
      {
        id: 6,
        text: "Alcance sus objetivos financieros estableciendo presupuestos, gestionando gastos y realizando un seguimiento de los ahorros."
      }
    ]
  };

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container pb-0">
        <h1>Cuenta de Cheques</h1>
        <div className="row justify-content-between row">
          <div className="col-md-7 mb-3 mb-md-0">
            <h2>Obtenga acceso a miles de cajeros automáticos sin cargos con Best Bank</h2>
            <p>
              Ya sea que esté buscando una cuenta de cheques gratuita o una cuenta con recompensas e intereses, tenemos
              cuentas que se adaptan a sus necesidades. Permítanos mostrarle por qué WaFd Bank ha sido elegido como el
              mejor banco!
            </p>
          </div>
          <div className="col-md-5 col-lg-4">
            <Link to="/about-us/best-bank" id="clickable-best-banks-image" className="stretched-link">
              <StaticImage
                src="../../../images/newsweek-3yr-lockup-600.png"
                alt="WaFd Bank America's Best Bank 3 years in a row by Newsweek."
                placeholder="blurred"
                loading="eager"
                quality="100"
              />
            </Link>
          </div>
        </div>
      </section>

      <CheckingOverviewSpanish />
      <MiniBand sectionClass="bg-white py-0" />
      <section className="bg-blue-10">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mb-3">
              <StaticImage
                src="../../../images/thumbnail-fresh-start-03312023-730.jpg"
                alt="Happy couple dancing together in the kitchen"
                placeholder="blurred"
                quality="100"
                loading="eager"
              />
            </div>
            <div className="col-md-6">
              <h3 className="font-weight-bold">No puede abrir una cuenta de banco tradicional?</h3>
              <p>
                Empiece de cero con una Cuenta Nuevo Comienzo de WaFd Bank. Si se le ha rechazado una cuenta de banco,
                nuestra Cuenta Nuevo Comienzo le puede ayudar a regresar al sistema bancario.
              </p>
              <div>
                <Link
                  id="fresh-start-page-link"
                  to="/es/banca-personal/cuenta-nuevo-comienzo"
                  className="btn btn-primary w-100 w-sm-auto"
                >
                  Aprenda Sobre la Cuenta Nuevo Comienzo
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Online Banking */}
      <section id="banca-en-linea-seccion" className="container">
        <div className="row">
          <div className="col-lg-5 mb-3 mb-lg-0">
            <h3 className="text-success font-weight-semi-bold">Banca en línea cómoda y segura</h3>
            <List {...onlineMobileBankingListItems} />
            <Link
              to="/personal-banking/online-banking"
              id="online-banking-page-link"
              className="btn btn-link w-100 w-sm-auto"
            >
              Aplicación y banca en línea de WaFd Bank
            </Link>
          </div>
          <div className="col-lg-7 row">
            <div className="col-md-6 d-none d-md-block">
              <img src={WaFdMobileApp} className="w-100 w-sm-50 w-md-100" alt="WaFd Bank Mobile App home screen" />
            </div>
            <div className="col-md-6">
              <p className="text-success font-weight-bold text-sm">Obtenga la aplicación móvil de WaFd Bank</p>
              <div className="row">
                <div className="col-6 col-md-auto col-lg-6 d-none d-md-block">
                  <StaticImage
                    src="../../../images/wafd-mobile-app-qr-code.png"
                    alt="WaFd App QR Code"
                    placeholder="blurred"
                    quality="100"
                    className="mb-3"
                  />
                  <p className="text-gray-60 text-nowrap text-sm">Escanear para descargar</p>
                </div>
                <div className="col-sm-6 d-flex flex-row flex-md-column">
                  <div className="mr-4 mr-md-0">
                    <MobileAppDownloadBtn
                      idName="apple-store"
                      storeBtn="apple"
                      url="https://apps.apple.com/us/app/wafd-bank/id1054549841"
                    />
                    <p className="text-gray-60 text-nowrap text-sm">Para dispositivos Apple</p>
                  </div>
                  <div className="">
                    <MobileAppDownloadBtn
                      idName="google-play-store"
                      storeBtn="google"
                      url="https://play.google.com/store/apps/details?id=com.washingtonfederal.mobilebanking&amp;hl=en_US"
                    />
                    <p className="text-gray-60 text-nowrap text-sm">Para dispositivos Android</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ContactInfo isSpanish={true} />
    </SecondaryLanding>
  );
};

export default CheckingAccountSpanish;
