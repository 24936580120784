import React from "react";
import { useLanguageContext } from "../../contexts/language-context";

const MiniBand = (props) => {
  const isSpanish = useLanguageContext();
  const miniBandData = [
    {
      text: "No-fee bill pay and person-to-person payments",
      esText: "Pago de facturas sin cargo y pagos de persona a persona",
      icon: "icon-receipt.svg",
      id: "mini-band-bill-pay"
    },
    {
      text: "Thousands of FEE-FREE ATMs",
      icon: "icon-atm.svg",
      esText: "Miles de cajeros automáticos sin cargos",
      id: "mini-band-atms"
    },
    {
      text: "24/7 online &amp; mobile banking with mobile check deposit",
      esText: "Banca móvil y en línea las 24 horas, los 7 días de la semana, con depósito de cheques móvil",
      icon: "icon-online-mobile-banking.svg",
      id: "mini-band-mobile-online-banking"
    },
    {
      text: "Over 200 branches in 9 states to serve you",
      esText: "Más de 200 sucursales en 9 estados para servirle",
      icon: "icon-pin.svg",
      id: "mini-band-locations"
    }
  ];
  return (
    <section id="mini-band-section" className={props.sectionClass}>
      <div className="container">
        <ul className="font-weight-bold list-unstyled no-gutters row row-cols-1 row-cols-md-4 row-cols-sm-2">
          {miniBandData.map((data, index) => (
            <li className="align-items-center d-flex d-sm-block text-sm-center position-relative" key={index}>
              <img src={`/images/icons/${data.icon}`} alt="" className="mb-sm-3 mr-3 mr-sm-0" />
              <div dangerouslySetInnerHTML={{ __html: isSpanish ? data.esText : data.text }} />
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};
export default MiniBand;

MiniBand.defaultProps = {
  sectionClass: "bg-white"
};
